import React from 'react'
import { Container } from 'react-bootstrap'
import './SecondSectionContactbackup.css'

export default function SecondSectionContactbackup() {
  return (
    <section className='parallax-contactsbackup'>
      <Container>
        <div className='main-txt'>
          <h1 className='secTitle'>What is VCard? </h1>
          <p className=''>vCard, also known as VCF (Virtual Contact File) is a file format standard for electronic business cards.
           vCards can be attached to e-mail messages, sent via Multimedia Messaging Service (MMS),
            on the World Wide Web, instant messaging, NFC or through QR code. 
            They can contain name and address information, phone numbers, e-mail addresses, URLs, logos, photographs, and audio clips.
             </p>
        </div>
      </Container>
    </section>
  )
}

