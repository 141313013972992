import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './DownloadSecCadToPdf.css'
import { BorderAll } from '@mui/icons-material'

export default function DownloadSecCadToPdf() {
    return (
        <section className='download-cadtopdf'>
            <Container>
                <Row>
                    <Col sm={12} md={6}>
                        <div className='dc-first-column text-center text-white'>
                            <img className='mb-2 cadtopdf-img' src="/images/icons/cadtopdf.jpg" width={100} alt="cadToPdf_icon" />
                            <h3 className=''>CAD To PDF Converter</h3>
                            <p> Convert DWG/DXF files to PDF quickly and efficiently,<br/> ensuring high-quality results.</p>
                            <a href='https://apps.microsoft.com/detail/9nxmz4hmpxrn?ocid=pdpshare&hl=en-us&gl=US' target="_blank" className='btn btn-primary'>Download</a>
                        </div>
                    </Col>
                    <Col>
                        <div className='dc-sec-column text-white text-center'>
                            <h1 className='display-6'>DWG/DXF to PDF Converter</h1>
                            <p>The CAD to PDF converter delivers precise, high-quality PDFs, ensuring accurate representation of your designs for professional use.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
