import React from 'react'
import './Vcardviewer.css'
import Header from '../../Header/Header'
import Footer from '../../Footer/Footer'
import TopSectionVcard from './TopSectionVcard/TopSectionVcard'
import SecondSectionVcard from './SecondSectionVcard/SecondSectionVcard'
import ExportSectionVcard from './ExportSectionVcard/ExportSectionVcard'
import DownloadSectionVcard from './DownloadSectionVcard/DownloadSectionVcard'
import { TabTitle } from '../../../utils/GeneralFunctions'

export default function Vcardviewer() {
    TabTitle('ARTHOUSE - VCard Viewer')
    return (
        <div>
            <Header />
            <DownloadSectionVcard />
            <TopSectionVcard />
            <SecondSectionVcard />
            <ExportSectionVcard />
            <Footer />
        </div>
    )
}
