import React from 'react'
import { Container } from 'react-bootstrap'
import './TopSectionVcard.css'

export default function TopSectionVcard() {
    return (
        <section className='ts-vcard'>
            <Container>
                <div className='ts-vcard-main text-white'>
                    <h3 className='tp-vc-title'>View VCF Files & Mac Contacts</h3>
                    <p>With vCard viewer app, You can run vcf files and also access the contacts in the contacts application</p>
                    <img className='ts-vcard-img mt-4' src="/images/products/vcardviewer/Tops-vcard.png" alt="vcard viewer" />
                </div>
            </Container>
        </section>
    )
}
