import React from 'react'
import Enavbar from '../Enavbar/Enavbar'


export default function Header() {
  return (
    <div>
      <Enavbar />
    </div>
  )
}
