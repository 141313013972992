import React from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom'
import './TopSecCadToPdf.css'

export default function TopSecCadToPdf() {
    return (
        <section className='ts-CadToPdf'>
            <Container>
                <div className='ts-CadToPdf-main text-white'>
                    <h3 className='tp-CadToPdf-title'>Quick and Easy PDF Converter</h3>
                    <p className='ts-parag'>With CAD To PDF Converter app, you can easily select one or multiple files and convert them to PDF with a single click.<br/>It’s a quick and convenient way to handle your files, making the process smooth and effortless.</p>
                    <img className='ts-CadToPdf-img mt-4' src="/images/products/cadtopdf/01.png" alt="cad to pdf" />
                </div>
            </Container>
        </section>
    )
}