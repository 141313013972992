import React from 'react'
import './About.css'
import Enavnar from '../Enavbar/Enavbar'
import Footer from '../Footer/Footer'
import { Container, Row, Col, Form } from 'react-bootstrap'
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom'
import { TabTitle } from '../../utils/GeneralFunctions'

export default function About() {
    TabTitle('ARTHOUSE - About')
    return (
        <section className='about-section'>
            <Enavnar />
            <div className='about-main'>
                <Container>
                    <Col>
                        <div className='main-text'>
                            <div className="asghar">

                                <h1 className='about-title'>Who we are</h1>
                                <p className='about-description'>Our team at Arthouse Studio brings over two decades of expertise in programming, educational content creation, and professional photography. By uniting specialists from diverse fields, 
                                    we aim to bridge these disciplines through the digital world and artificial intelligence, driving job creation and enhancing quality of life.</p>
                                <p className='about-description'>
                                Arthouse experts are actively researching various industries to identify their needs and address them by developing smartphone applications. Our goal is to deliver faster, more convenient solutions for users while prioritizing privacy and security.</p>
                            </div>
                        </div>
                    </Col>
                </Container>
            </div>
            <Container fluid>
                <div className='secound-mail-row'>
                    <Row >
                        <Col sm={12} md={6} className='asghar'>
                            <div className='contact'>
                                <h1 className='about-title'>Contact Us</h1>
                                <p>We eagerly welcome your comments and suggestions <br /> to improve the quality of Arthouse services.</p>
                                <Link to='/contact' className='btn btn-danger'>Contact Us</Link>
                            </div>
                        </Col>
                        <Col className='contact-right'>
                            <p >Enter your email to receive the latest topics of the blog newsletter.</p>
                            <Form action='/' className='email-form'>
                                <Form.Group className="mb-4 email-FG" controlId="formBasicEmail">
                                    {/* <Form.Label>Email address</Form.Label> */}
                                    <Form.Control type="email" placeholder="Enter email" />
                                    <Form.Text className="text-muted">
                                    </Form.Text>
                                </Form.Group>
                                <Button type='submit' variant="contained" size='small' endIcon={<SendIcon />}>
                                    Send
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                </div>
            </Container>



            <Footer />
        </section>
    )
}
