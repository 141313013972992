import React from 'react'
import Header from '../Header/Header'
import ECarosel from './carousel/ECarousel'
import Footer from '../Footer/Footer'
import './Home.css'
import Productcard from './Productcard/Productcard'
import { TabTitle } from '../../utils/GeneralFunctions'


export default function Home() {
    TabTitle('ARTHOUSE');
    return (
        <div>
            <Header />
            <div className='top-home'>
                <div className='home-text'>
                    <h1 className='display-6'>Welcome To</h1><h1 className='home-name' >ARTHOUSE</h1> 
                    <p>The Home As Beautiful As Art</p>
                </div>
            </div>
            <ECarosel className='ecarosel' />
            <Productcard />
            <Footer />
        </div>
    )
}
