import React from 'react'
import './SecSceCadToPdf.css'
import { Container, Row, Col, Form } from 'react-bootstrap'
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom'

export default function SecSceCadToPdf() {
    return (
        <section className='ss-CadToPdf'>
            <Container>
                <Row>
                    <Col md={6}>
                        <img className='ss-CadToPdf-img mt-4' src="/images/products/cadtopdf/02.png" alt="cad to pdf" />
                    </Col>
                    <Col md={6} className="d-flex align-items-center justify-content-center">
                        <div className='ss-CadToPdf-main text-white'>
                            <h3 className='ss-CadToPdf-title'>Merged or Split PDF Output</h3>
                            <p className="mx-auto ss-parag" style={{ maxWidth: '600px' }}>
                                With CAD To PDF Converter app, you have the flexibility to receive your output in two ways. 
                                You can either get each PDF file separately as individual documents, 
                                or you can merge them all together into one single file. This allows you to customize the output based on your needs,
                                making file management more efficient and tailored to your preferences.
                            </p>
                        </div>
                    </Col>
                </Row>
                
            </Container>
        </section>
    )
}