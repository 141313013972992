import React from 'react'
import './Contact.css'
import Enavnar from '../Enavbar/Enavbar'
import Footer from '../Footer/Footer'
import { Container, Row, Col, Navbar, Nav } from 'react-bootstrap'
import { AiFillFacebook, AiFillYoutube, AiFillLinkedin } from 'react-icons/ai'
import { BsTwitter } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { TabTitle } from '../../utils/GeneralFunctions'


export default function Contact() {
    TabTitle('ARTHOUSE - Contact Us')
    return (
        <section>
            <Enavnar />
            <div className='contact-main'>
            </div>
            <Container>
                <div className='secound-contact-row'>
                    <Row>
                        <Col sm={12} md={6} className='asghar'>
                            <div className='social'>
                                <h4 className='contact-title'>Contact Us</h4>
                                <p>We eagerly welcome your comments and suggestions<br /> to improve the quality of Arthouse services.</p>
                                <a href="mailto: Support@arthousestudio.net ">Support@arthousestudio.net</a>
                            </div>
                        </Col>
                        <Col className='social-right'>
                            <h4 className='contact-title'>Fallow Us</h4>
                            <Nav.Link className='social-contact' href=""><BsTwitter className='text-info' /> Twitter</Nav.Link>
                            <Nav.Link className='social-contact' href=""><AiFillFacebook className='text-primary' /> Facebook</Nav.Link>
                            <Nav.Link className='social-contact' href=""><AiFillYoutube className='text-danger' /> Youtube</Nav.Link>
                            <Nav.Link className='social-contact' href=""><AiFillLinkedin className='text-primary' /> Linkdin</Nav.Link>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Footer />
        </section>
    )
}
