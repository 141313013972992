import React from 'react'
import './ThirdSectionContactbackup.css'
import { Container, Row, Col } from 'react-bootstrap'

export default function ThirdSectionContactbackup() {
    return (
        <section className='main-contactbackup'>
            <Container>
                <Row>
                    <Col>
                        {/* <Col className='th-mobile-column'>
                            <img src='/images/iphone3.png' width='200' className='mobile' />
                        </Col>
                        <Col className='ali'>
                            <h2 className='third-section-text lead text-primary'>You Can Customize Or<br /> Remove Your Location.</h2>
                        </Col> */}
                    </Col>
                    <Col sm={12} md={6}>
                        <div className='cbsec-txt-column'>
                            <h1 className='display-6 text-white'>Useful Tools Section </h1>
                            <p className='text-white'>With the Contacts backup utility app, In the useful tools section, you can easily find duplicate contacts,
                                contacts without names and even without numbers,
                                and also dark mode feature that is clear in the background.</p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </section>
    )
}
