import React from 'react'
import './FifthSection.css'
import { Container, Table } from 'react-bootstrap'
import { FaCheck } from 'react-icons/fa'
import { FaTimes } from 'react-icons/fa'

export default function FifthSection() {
    return (
        <section className='F-main' >
            <p className='F-header-title'>Compare Basic and Premium versions</p>
                <div className="table-responsive">
                    <table className="table center table-hover" >
                        <thead>
                            <tr className="tb-tr">
                                <th>#</th>
                                <th><strong>Feature</strong></th>
                                <th><strong>Basic</strong></th>
                                <th><strong>Premium</strong></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="tb-tr">
                                <th className='f-row'>1</th>
                                <th>Albums by Camera Model</th>
                                <td className='th-row'><FaTimes className='text-danger' /></td>
                                <td className='th-row'><FaCheck className='text-success' /></td>
                            </tr>
                            <tr className="tb-tr">
                                <th className='f-row'>2</th>
                                <th>Albums by lense model</th>
                                <td><FaTimes className='text-danger' /></td>
                                <td><FaCheck className='text-success' /></td>
                            </tr>
                            <tr className="tb-tr">
                                <th className='f-row'>3</th>
                                <th>Star Rating Albums</th>
                                <td><FaTimes className='text-danger' /></td>
                                <td><FaCheck className='text-success' /></td>
                            </tr>
                            <tr className="tb-tr">
                                <th className='f-row'>4</th>
                                <th>Edit or Erase metadata</th>
                                <td><FaTimes className='text-danger' /></td>
                                <td><FaCheck className='text-success' /></td>
                            </tr>
                            <tr className="tb-tr">
                                <th className='f-row'>5</th>
                                <th>Set Gps Location</th>
                                <td><FaTimes className='text-danger' /></td>
                                <td><FaCheck className='text-success' /></td>
                            </tr>
                            <tr className="tb-tr">
                                <th className='f-row'>6</th>
                                <th>Remove Location</th>
                                <td><FaTimes className='text-danger' /></td>
                                <td><FaCheck className='text-success' /></td>
                            </tr>
                            <tr className="tb-tr">
                                <th className='f-row'>7</th>
                                <th>Star Rating Photos</th>
                                <td><FaCheck className='text-success' /></td>
                                <td><FaCheck className='text-success' /></td>
                            </tr>
                            <tr className="tb-tr">
                                <th className='f-row'>8</th>
                                <th>Display Meta Data</th>
                                <td><FaCheck className='text-success' /></td>
                                <td><FaCheck className='text-success' /></td>
                            </tr>
                            <tr className="tb-tr">
                                <th className='f-row'>9</th>
                                <th>Share Meta Data</th>
                                <td><FaCheck className='text-success' /></td>
                                <td><FaCheck className='text-success' /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        </section>
    )
}
