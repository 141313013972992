import React from 'react'
import Header from '../../Header/Header'
import TopSection from './TopSection/TopSection'
import SecondSection from './SecondSection/SecondSection'
import ThirdSection from './ThirdSection/ThirdSection'
import FourthSection from './FourthSection/FourthSection'
import FifthSection from './FifthSection/FifthSection'
import QuestionSection from './QuestionSection/QuestionSection'
import Footer from '../../Footer/Footer'
import { TabTitle } from '../../../utils/GeneralFunctions'

export default function Home() {
    TabTitle('ARTHOUSE - Exif Pro')
    return (
        <div>
            <Header />
            <TopSection />
            <SecondSection />
            <ThirdSection />
            <FourthSection />
            <FifthSection />
            <QuestionSection />
            <Footer />
        </div>
    )
}
