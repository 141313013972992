import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './DownloadSectionVcard.css'

export default function DownloadSectionVcard() {
    return (
        <section className='download-vcard'>
            <Container>
                <Row>
                    <Col sm={12} md={6}>
                        <div className='dc-first-column text-center text-white'>
                            <img className='mb-2' src="/images/products/vcardviewer/vcard-icon.png" width={100} alt="vcardviewer_icon" />
                            <h3 className=''>VCard Viewer</h3>
                            <p> View vcf file Contacts and import <br />it to mac contacts</p>
                            <a href='https://apps.apple.com/us/app/vcard-viewer/id1554042199' target="_blank" className='btn btn-primary'>Download</a>
                        </div>
                    </Col>
                    <Col>
                        <div className='dc-sec-column text-white text-center'>
                            <h1 className='display-6'>vCard, vcf and Contacts Tool</h1>
                            <p>With our Contacts backup you can backup your contacts as vcf file and view that in your mac with VCard viewer.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
