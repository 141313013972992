import React from 'react'
import './SecondSectionVcard.css'
import { Container } from 'react-bootstrap'

export default function SecondSectionVcard() {
    return (
        <section className='Ss-vcard'>
            <Container>
                <div className='ss-vcard-main text-white'>
                    <h3 className='ss-vc-title'>Grouping contacts based on your needs</h3>
                    <p>With vCard viewer app, You can categorize your contacts by company, job title, country<br /> and city so that you can find them easily later.</p>
                    <img className='ss-vcard-img mt-4' src="/images/products/vcardviewer/ss-vcard.png" alt="vcard viewer" />
                </div>
            </Container>
        </section>
    )
}
