import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './TopSection.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Typography, Rating } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useState } from 'react';

export default function TopSection() {

    const [value, setValue] = useState()

    return (


        <section className='topsecbg'>
            <Container>
                <Row>
                    <Col sm={12} md={6}>
                        <div className='cb-first-column'>
                            <Col>
                                <div className='cb-mobile-column'>
                                    <img src='/images/iphone.png' width='200' className='mobile' />
                                </div>
                            </Col>
                            <Col>
                                <div className='cb-appicon-column'>
                                    <img src='/images/appicon.png' width='100' className='cb-appicon' />
                                    <h3 className='text-white epiconName-size'>Exif Pro</h3>
                                    <h3 className='lead text-white mb-3'>All information <br /> at your fingertips.</h3>
                                    <a href="https://apps.apple.com/us/app/exif-pro/id1121970516" target="_blank" className='btn btn-primary downloadAppBtn'>Download For Free</a>
                                </div>
                            </Col>
                        </div>
                    </Col>
                    <Col>
                        <div className='cbtop-txt-column text-white text-center '>
                            <h1 className='display-6'>Give Your Photos 5 Stars
                                {/* stars */}
                                <Typography className='abh' component="legend"></Typography>
                                <Rating
                                    emptyIcon={<StarIcon style={{ color: 'white', opacity: .2 }} fontSize="inherit" />}
                                    name="simple-controlled"
                                    value={value}
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                    }}
                                />
                            </h1>
                            <p>With Exif Pro, you can rate your photos from 1 to 5 stars so that you can find them more easily.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

    )
}



