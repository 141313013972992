import React from 'react'
import './QuestionSection.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { FiArrowUp } from 'react-icons/fi'
import { useState } from 'react';

export default function QuestionSection() {



    return (
        <section className='Question-main'>
            <Container>
                <p className='F-header-title'>Frequently Ask Questions </p>


                <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className='F-title'>How Can I Download Exif Pro App?</Accordion.Header>
                        <Accordion.Body>
                        To download the Exif Pro app, simply tap the ‘Download for Free’ button at the top of the page, or visit the Apple App Store and search for ‘Exif Pro.’ Once you find the app, tap the ‘Get’ button to install it.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header className='F-title'>What's The Best Exif Data App For iOS?</Accordion.Header>
                        <Accordion.Body>
                        The ideal Exif data app not only provides comprehensive details about the metadata of your photos but also allows full control over editing or deleting this information. It categorizes photos for easier access and display. We present the Exif Pro app, which offers all these features, along with future updates that will introduce even more functionality.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header className='F-title'>How Can I Edit Meta Data?</Accordion.Header>
                        <Accordion.Body>
                        With the Exif Pro app, you can easily delete or modify photo metadata, including camera details, lens information, shooting location, and other related data. For a detailed tutorial on using Exif Pro, I recommend visiting our blog.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header className='F-title'>Is Exif Pro Safe about Your Personal Information?</Accordion.Header>
                        <Accordion.Body>
                        The Exif Pro app does not collect, sell, or share your personal information or photo data with any company or third party. All information remains visible only to you and is never transferred outside the app under any circumstances. You can confidently use all the app’s features, knowing that we are fully committed to protecting your privacy.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header className='F-title'>Can I Use Exif Pro For Dslr Or Mirrorless Camera?</Accordion.Header>
                        <Accordion.Body>
                        The Exif Pro app is compatible with all mirrorless and DSLR cameras. Simply import your camera photos into your iPhone using any method, and let Exif Pro handle the rest. With advanced features tailored for professional cameras, the app efficiently categorizes photos based on camera type and lens, ensuring a seamless experience.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </section>
    )
}
