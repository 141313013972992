import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './TopSectionContactbackup.css'
import 'bootstrap/dist/css/bootstrap.min.css';

export default function TopSectionContactbackup() {


    return (
        <section className='topsec-contactbg'>
            <Container>
                <Row>
                    <Col sm={12} md={6}>
                        <div className='cb-first-column'>
                            <Col>
                                <div className='cb-mobile-column'>
                                    <img src='/images/iphoneContactbackup.png' width='200' className='cb-top-mobile' />
                                </div>
                            </Col>
                            <Col>
                                <div className='cb-appicon-column'>
                                    <img src='/images/contact-icon.png' width='100' className='cb-appicon' alt='contact backup' />
                                    <h3 className='text-white cbiconName-size'>Contacts<br /> Backup</h3>
                                    <h3 className='lead text-white mb-3'>Simple, specific<br /> but widely used.</h3>
                                    <a href="https://apps.apple.com/in/app/contacts-backup-utility/id1451401194" target="_blank" className='btn btn-primary'>Download</a>
                                </div>
                            </Col>
                        </div>
                    </Col>
                    <Col >
                        <div className='cbtop-txt-column text-black text-center '>
                            <h1 className='display-6'>Backup your contacts and sleep well.</h1>
                            <p>With Contacts backup, you can backup your contacts and share VCard with other devices.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}










{/* <div>

</div> */}


