import React from 'react'
import Enavbar from '../../Enavbar/Enavbar'
import Footer from '../../Footer/Footer'
import TopSecCadToPdf from './TopSecCadToPdf/TopSecCadToPdf'
import SecSceCadToPdf from './SecSceCadToPdf/SecSceCadToPdf'
import DownloadSecCadToPdf from './DownloadSecCadToPdf/DownloadSecCadToPdf'
import { TabTitle } from '../../../utils/GeneralFunctions'
import ThirdSceCadToPdf from './ThirdSceCadToPdf/ThirdSceCadToPdf'


export default function CadToPdfConverter() {
    TabTitle('ARTHOUSE - CAD To PDF')
  return (
    <section>
      <Enavbar />
      <DownloadSecCadToPdf />
      <TopSecCadToPdf />
      <SecSceCadToPdf />
      <ThirdSceCadToPdf />
      <Footer />
    </section>
  )
}
