import React from 'react'
import './ThirdSceCadToPdf.css'
import { Container, Row, Col, Form } from 'react-bootstrap'
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { Link } from 'react-router-dom'
import PaletteIcon from '@mui/icons-material/Palette';
import DescriptionIcon from '@mui/icons-material/Description';
import LineWeightIcon from '@mui/icons-material/LineWeight';


export default function ThirdSceCadToPdf() {
    return (
        <section className='ths-CadToPdf'>
            <Container>
                <Row>
                    <Col md={6} className="d-flex align-items-center justify-content-center">
                        <div className='ths-CadToPdf-main text-white'>
                        <h3 className='ths-CadToPdf-title mb-4'>Advanced Output Features</h3>
                        <ul className='ths-CadToPdf-advantages'>
                            <li className="d-flex align-items-center mb-1">
                            <PaletteIcon className="me-2" style={{ fontSize: 30 }} />
                            Output in Color, Grayscale or Black.
                            </li>
                            <li className="d-flex align-items-center mb-1">
                            <DescriptionIcon className="me-2" style={{ fontSize: 30 }} />
                            Choose Paper Size for Output.
                            </li>
                            <li className="d-flex align-items-center">
                            <LineWeightIcon className="me-2" style={{ fontSize: 30 }} />
                            Choose Actual Line Weight or Same Line Weight for All.
                            </li>
                        </ul>
                        </div>
                    </Col>
          <Col md={6}>
            <img className='ths-CadToPdf-img mt-4' src="/images/products/cadtopdf/03.png" alt="cad to pdf" />
          </Col>
                </Row>
                
            </Container>
        </section>
    )
}