import React from 'react'
import './Productcard.css'
import { Container, Image, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Productcard() {
    return (
        <section>
            <Container className='Card-width'>
                <Row className='pc-row'>
                    <Col sm={12} md={6} lg={4}>
                        <div className="product-card pc-gradiant-exifpro">
                            <div className="nametag pc-nametag-exifpro">
                                <h6 className="nametag-text">Exif data viewer</h6>
                            </div>
                            <div className='card-txt '>
                                <Image src='/images/appicon.png' width='100' className='product-appicon' />
                                <h4 className='product-title'>Exif Pro</h4>
                                <p>All photos information viewer (Exif data)
                                    with <br /> the ability to change or remove them. </p>
                            </div>
                            <div className="product-link">
                                <a href="https://apps.apple.com/us/app/exif-pro/id1121970516" target="_blank" className='btn btn-primary down-link card-link' >Download</a>
                                <Link to="/products/exifpro" className='btn btn-outline-secondary card-link'>Learn more</Link>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="product-card pc-gradiant-contact">
                            <div className="nametag pc-nametag-contact">
                                <h6 className="nametag-text">Contact Backup</h6>
                            </div>
                            <div className='card-txt '>
                                <Image src='/images/icons/contact-icon.jpg' width='100' className='product-appicon' />
                                <h4 className='product-title'>Contact Backup</h4>
                                <p>Saving contacts information as a backup  and <br/>ability to share with other devices. </p>
                            </div>
                            <div className="product-link">
                                <a href="https://apps.apple.com/in/app/contacts-backup-utility/id1451401194" target="_blank" className='btn btn-primary down-link card-link' >Download</a>
                                <Link to="/products/contactsbackup" className='btn btn-outline-secondary card-link'>Learn more</Link>
                            </div>
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                        <div className="product-card pc-gradiant-vcard">
                            <div className="nametag pc-nametag-vcard">
                                <h6 className="nametag-text">VCard Viewer</h6>
                            </div>
                            <div className='card-txt '>
                                <Image src='/images/icons/vcardviewer-icon.jpg' width='100' className='product-appicon' />
                                <h4 className='product-title'>VCard Viewer</h4>
                                <p>View vcf file Contacts and import  it to<br /> mac contacts. </p>
                            </div>
                            <div className="product-link">
                                <a href="https://apps.apple.com/us/app/vcard-viewer/id1554042199" target="_blank" className='btn btn-primary down-link card-link' >Download</a>
                                <Link to="/products/vcardviewer" className='btn btn-outline-secondary card-link'>Learn more</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='pc-row'>
                    <Col sm={12} md={6} lg={4}>
                        <div className="product-card pc-gradiant-cadToPdf">
                            <div className="nametag pc-nametag-cadToPdf">
                                <h6 className="nametag-text">CAD To PDF Converter</h6>
                            </div>
                            <div className='card-txt '>
                                <Image src='/images/icons/cadtopdf.jpg' width='100' className='product-appicon' />
                                <h4 className='product-title'>CAD To PDF Converter</h4>
                                <p>The CAD to PDF converter creates accurate PDFs<br/>from CAD files for professional use.</p>
                            </div>
                            <div className="product-link">
                                <a href="https://apps.microsoft.com/detail/9nxmz4hmpxrn?ocid=pdpshare&hl=en-us&gl=US" target="_blank" className='btn btn-primary down-link card-link' >Download</a>
                                <Link to="/products/CadToPdfConverter" className='btn btn-outline-secondary card-link'>Learn more</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

        </section>
    )
}
