import { buttonBaseClasses } from '@mui/material';
import React from 'react'
import { Container } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import './ECarosel.css'

export default function ECarousel() {
    return (

        <div className='carosel-main ecarosel'>

            <Carousel>
                <Carousel.Item interval={3000}>
                    <a href='/products/exifpro'>
                        <img
                            className="img-ca"
                            src="images/posts/Exifpto-slide.jpg"
                            alt="Image One"
                        />
                    </a>
                    <Carousel.Caption>
                        {/* <Link to='' className='btn btn-danger'>Download</Link> */}
                    </Carousel.Caption>
                    {/* <Carousel.Caption>
                        <Link to='/products/vcardviewer'><h3>Label for first slide</h3>
                            <p>Sample Text for Image One</p></Link>
                        </Carousel.Caption> */}
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <a href='/products/contactsbackup'>
                        <img
                            className="img-ca "
                            src="images/posts/Contactbackup-slide.jpg"
                            alt="Image Two"
                        />
                    </a>
                    <Carousel.Caption>
                        {/* <Link to='' className='btn btn-danger'>Download</Link> */}
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                    <a href='/products/vcardviewer'>
                        <img
                            className="img-ca "
                            src="images/posts/Vcard-slide.jpg"
                            alt="Image Two"
                        />
                    </a>
                    <Carousel.Caption>
                        {/* <Link to='' className='btn btn-danger'>Download</Link> */}
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>

    )
}
