import React, { useState, useRef } from 'react'
import './Enavbar.css'
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';


export default function Enavbar() {

    const [isActive, setIsActive] = useState(false);
    const btnRef = useRef();

    useEffect(() => {

        const closeDropdown = e => {
            if (e.path[1] !== btnRef.current) {
                setIsActive(false)
            }
        }
        document.body.addEventListener('click', closeDropdown)
        return () => document.body.removeEventListener('click', closeDropdown)
    }, []);


    return (

        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand><Link to='/' className='nlink-logo'><img src='/images/exifLogo.png' width='60' />ARTHOUSE</Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link to="/" className='nlink'>Home</Link>

                        <div className='products ' ref={btnRef} onClick={(e) => setIsActive(!isActive)}>
                            <Link to="" className='nlink'>Products</Link>

                            {isActive &&
                                <div className='products-menu fade-in'>
                                    <Link to="/Products/exifpro" className='nlink pb-2'>Exif Pro</Link>
                                    <Link to="/Products/contactsbackup" className='nlink pb-2'>Contacts Backup</Link>
                                    <Link to="/Products/vcardviewer" className='nlink pb-2'>VCard Viewer</Link>
                                    <Link to="/Products/cadToPdfConverter" className='nlink pb-2'>CAD To PDF Converter</Link>
                                </div>
                            }
                        </div>

                        <Link to="/contact" className='nlink'>Contact</Link>
                        <Link to="/about" className='nlink'>About</Link>





                        {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                            <NavDropdown.Divider /><NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
