import React from 'react'
import { Container, Row, Col, Nav,Navbar } from 'react-bootstrap'
import {BsTwitter} from 'react-icons/bs'
import{AiFillFacebook} from 'react-icons/ai'
import{AiFillYoutube} from 'react-icons/ai'
import{AiFillLinkedin} from 'react-icons/ai'
import { Link } from 'react-router-dom'


import './Footer.css'

export default function Footer() {
    return (
        <section className='footer'>
            <Container>
                <Row>
                    <Col>
                        <ul className='l-ul'>
                            <li className='l-items'><strong>Company</strong></li>
                            <li className='l-items'><a href="/">Products</a></li>
                            <li className='l-items'><Link to="/">Home</Link></li>
                            <li className='l-items'><Link to="/about">About</Link></li>
                            <li className='l-items'><Link to="/contact">Contact</Link></li>
                        </ul>
                    </Col>
                    <Col>
                        <ul className='l-ul'>
                            <li className='l-items'><strong>Products</strong></li>
                            <li className='l-items'><Link to="/products/exifpro">Exif Pro</Link></li>
                            <li className='l-items'><Link to="/products/contactsbackup">Contacts Backup</Link></li>
                            <li className='l-items'><Link to="/products/vcardviewer">VCard Viewer</Link></li>
                        </ul>
                    </Col>
                    <Col>
                        <ul className='l-ul'>
                            <li className='l-items'><strong>Support</strong></li>
                            <li className='l-items'><a href="">Knowledge Base</a></li>
                            <li className='l-items'><a href="">Frequently Questions</a></li>
                            <li className='l-items'><a href=""></a></li>
                        </ul>
                    </Col>
                    <Col>
                        <ul className='l-ul'>
                            <li className='l-items'><strong>Policy</strong></li>
                            <li className='l-items'><a href="">Privacy Policy</a></li>
                            <li className='l-items'><a href="">Cookie Policy</a></li>
                            <li className='l-items'><a href=""></a></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Navbar>
                            <Nav className="me-auto">
                                <Nav.Link className='social-lists' href="#home"><BsTwitter className='text-info'/> Twitter</Nav.Link>
                                <Nav.Link className='social-lists' href="#features"><AiFillFacebook className='text-primary'/> Facebook</Nav.Link>
                                <Nav.Link className='social-lists' href="#pricing"><AiFillYoutube className='text-danger'/> Youtube</Nav.Link>
                                <Nav.Link className='social-lists' href="#pricing"><AiFillLinkedin className='text-primary'/> Linkdin</Nav.Link>
                            </Nav>
                        </Navbar>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <p>Copyright © 2024 ARTHOUSE studio Team, Inc</p>
                </Row>
            </Container>
        </section>
    )
}
