import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './FourthSection.css'

export default function FourthSection() {
    return (
        <section className='Fsec'>
            <Container>
                <h2 className='F-header-title'>Meet More Features With Exif Pro</h2>
                <div className='fsec-content'>
                    <Row>
                        <Col xs={12} md={6} className='left-column' >
                            <Row>
                                <Col xs={12} md={6} className='img-column'>
                                    <img src="/images/icons/gallery.png" width='80' height='80' alt="" />
                                </Col>
                                <Col>
                                    <h4 className='F-title'>Albums by Camera Model</h4>
                                    <p className=''>With this feature, you can categorize your photos by camera model so that you can easily find them, especially when you have a large archive. </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} className='img-column'>
                                    <img src="/images/icons/star.png" width='80' height='80' alt="" />
                                </Col>
                                <Col>
                                    <h4 className='F-title'>Star Rating Albums</h4>
                                    <p className=''>There is a rating from 1 to 5 stars for the photo album in addition to rating the photos for better categorization and easier finding. </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} className='img-column'>
                                    <img src="/images/icons/location.png" width='80' height='80' alt="" />
                                </Col>
                                <Col>
                                    <h4 className='F-title'>Set Gps Location</h4>
                                    <p className=''>If you have a photo that does not have location information, you can easily set the location for that photo with the Exif Pro application.</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col  className='right-colunm'>
                            <Row>
                                <Col xs={12} md={6} className='img-column'>
                                    <img src="/images/icons/lens.png" width='80' height='80' alt="" />
                                </Col>
                                <Col>
                                    <h4 className='F-title'>Albums by lense model</h4>
                                    <p className=''>Categorizing by lens type is another feature which is very efficient, especially for those who use professional cameras. </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} className='img-column'>
                                    <img src="/images/icons/eraser.png" width='80' height='80' alt="" />
                                </Col>
                                <Col>
                                    <h4 className='F-title'>Edit or Erase metadata</h4>
                                    <p className=''>The most important features of this app is the ability to change photo metadata with your metadata and even remove them. </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} className='img-column'>
                                    <img src="/images/icons/removeL.png" width='80' height='80' alt="" />
                                </Col>
                                <Col>
                                    <h4 className='F-title'>Remove Location</h4>
                                    <p className=''>Removing Location is another feature of the Exif Pro app, which is used to set a new location for a photo or remove it from a photo.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section >
    )
}
