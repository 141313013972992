import React from 'react'
import { Container } from 'react-bootstrap'
import './SecondSection.css'

export default function SeconSection() {
  return (
    <section className='parallax'>
      <Container>
        <div className='main-txt'>
          <h1 className='secTitle'>What is Exif data? </h1>
          <p className=''>Exchangeable Image File Format (EXIF) is a standard that defines specific information 
          related to an image or other media captured by a digital camera. 
          It is capable of storing such important data as camera exposure,
           date/time the image was captured, and even GPS location.
          
          </p>
        </div>
      </Container>
    </section>
  )
}

