import React from 'react'
import './ThirdSection.css'
import { Container, Row, Col } from 'react-bootstrap'

export default function ThirdSection() {
    return (
        <section className='main'>
            <Container>
                <Row>
                    <Col className='third-sec-left'>
                        <Col className='th-mobile-column'>
                            <img src='/images/iphone3.png' width='200' className='mobile' />
                        </Col>
                        <Col className='ali'>
                            <h2 className='third-section-text lead text-primary'>You Can Customize Or<br /> Remove Your Location.</h2>
                        </Col>
                    </Col>
                    <Col className='th-text-right th-pad'>
                        <h1 className='display-6'>Find where did you take a shot</h1>
                        <p>With the Exif Pro app, you gain complete control over location services, allowing you to view photo locations, update them with your own, share locations with your contacts, or even remove location data from your photos entirely.</p>
                    </Col>

                </Row>
            </Container>
        </section>
    )
}
