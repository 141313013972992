import React from 'react'
import './ExportSectionVcard.css'
import { Container } from 'react-bootstrap'

export default function ExportSectionVcard() {
  return (
    <section className='Es-vcard'>
            <Container>
                <div className='Es-vcard-main text-white'>
                    <h3 className='Es-vc-title'>Export VCF & CSV Files </h3>
                    <p>With vCard viewer app, You can export your contacts in a vcf or csv file for other devices,<br /> and there is also the abilityto send directly through gmail and outlook.</p>
                    <img className='Es-vcard-img mt-4' src="/images/products/vcardviewer/Es-vcard.png" alt="vcard viewer" />
                </div>
            </Container>
        </section>
  )
}
