import React from 'react'
import './Contactsbackup.css'
import Enavbar from '../../Enavbar/Enavbar'
import Footer from '../../Footer/Footer'
import TopSectionContactbackup from './TopSectionContactbackup/TopSectionContactbackup'
import SecondSectionContactbackup from './SecondSectionContactbackup/SecondSectionContactbackup'
import ThirdSectionContactbackup from './ThirdSectionContactbackup/ThirdSectionContactbackup'
import { TabTitle } from '../../../utils/GeneralFunctions'

export default function Contactbackup() {
  TabTitle('ARTHOUSE - Contacts Backup')
  return (
    <section>
      <Enavbar />
      <TopSectionContactbackup />
      <SecondSectionContactbackup />
      <ThirdSectionContactbackup />
      <Footer />
    </section>
  )
}
